import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../components/CustomInput/CustomInput";
import { useRef, useState } from "react";
import { Cropper } from "react-cropper";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";
import { colors } from "../../utils/theme";
import regexList from "../../utils/regexList";
import { updateCompany } from "../../endpoints/company.service";
import { setUserData } from "../../store/actions/auth.action";
import { useSnackbar } from "notistack";

const MyCompanyDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cropperRef = useRef(null);
  const filePickerRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const { companyDetails } = useSelector((state) => state.auth);
  const {
    control,
    formState: { errors, isDirty },
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: companyDetails?.name,
      websiteUrl: companyDetails?.websiteUrl,
      address1: companyDetails?.address1,
      email: companyDetails?.email,
      city: companyDetails?.city,
      state: companyDetails?.state,
      zipCode: companyDetails?.zipCode,
      country: companyDetails?.country,
      primaryContactNumber: companyDetails?.primaryContactNumber,
      zipCode: companyDetails?.zipCode,
      // image: undefined,
      // profileImage: undefined,
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  console.log("companyDetails", companyDetails);

  const fields = watch();

  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      setValue("file", file, { shouldDirty: true });
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setValue("image", reader.result, { shouldDirty: true });
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      console.error("Error during file upload or API calls:", error);
    }
  };

  const handleItemClick = (item) => {
    navigate("/account-members-detail", { state: { item } });
  };

  const getCroppedImage = (e) => {
    e.preventDefault();

    if (cropperRef.current) {
      const cropperInstance = cropperRef.current.cropper;
      if (cropperInstance) {
        const croppedDataUrl = cropperInstance.getCroppedCanvas().toDataURL();
        setValue("profileImage", croppedDataUrl);
        setValue("image", undefined);
      } else {
        console.error("Cropper instance is not found");
      }
    } else {
      console.error("cropperRef.current is not initialized");
    }
  };

  const updateProfile = async (payload) => {
    return;
    try {
      setIsLoading(true);
      if (payload?.profileImage) {
        const { data } = await profileImage();
        if (!data?.data || !data?.data.uploadURL) {
          throw new Error("Failed to get upload URL");
        }
        const formData = new FormData();
        const base64Data = payload?.profileImage?.split(";base64,").pop();
        const byteArray = Uint8Array.from(atob(base64Data), (c) =>
          c.charCodeAt(0)
        );
        const blob = new Blob([byteArray], { type: payload?.file?.type });
        formData.append("file", blob, payload?.file?.name);
        const fileResponse = await uploadUrl(data?.data.uploadURL, formData);
        delete payload["file"];
        delete payload["profileImage"];
        delete payload["image"];
        const picURl = fileResponse?.variants?.find(
          (x) => x.split("/").slice(-1)[0] === "public"
        );
        payload["profilePic"] = picURl;
      }
      const { success, data: userApiData } = await updateCandidate(payload);
      if (success) {
        dispatch(setUserData(userApiData));
        setValue("profileImage", undefined);
        setValue("image", undefined);
        setValue("openProfileModel", false);
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateCompanyDetails = async (formData) => {
    const payload = { ...formData, companyId: companyDetails?._id };
    setIsLoading(true);
    try {
      const { success, data } = await updateCompany(payload);
      if (success) {
        dispatch(setUserData({ companyDetails: data }));
        enqueueSnackbar("Company details updated successfully", {
          variant: "success",
        });
      }
    } catch (error) {
      console.log("Error updating company details", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col overflow-y-scroll no-scrollbar h-full rounded-lg bg-white w-full flex-grow p-10">
      <div className="flex flex-col gap-5 rounded-md bg-gray-50 px-8 py-6">
        <div className="flex flex-col gap-1.5">
          <label className="font-bold text-lg text-primary">
            Update Your Company Details
          </label>
          <span className="font-medium text-xs text-gray-500">
            Keep Your Business Information Up to Date.
          </span>
        </div>
        <div className="grid grid-cols-2 gap-5">
          <CustomInput
            label="Company Name"
            name="name"
            placeholder={"ABC Company"}
            control={control}
            errors={errors}
          />
          <CustomInput
            label="Website URL"
            name="websiteUrl"
            placeholder={"Ex: doe"}
            control={control}
            errors={errors}
          />
          <CustomInput
            label="Company Email"
            name="email"
            placeholder={"Ex: doe"}
            rules={{
              required: "This field is required.",
              pattern: {
                value: regexList.email,
                message: "Please enter a valid email.",
              },
            }}
            control={control}
            errors={errors}
          />
          <CustomInput
            label="Primary Contact Number"
            name="primaryContactNumber"
            placeholder={"Ex: doe"}
            control={control}
            errors={errors}
          />
          {/* <CustomInput
            label="Primary Contact Number"
            name="primaryContactNumber"
            type='phone'
            placeholder={"Ex: doe"}
            control={control}
            errors={errors}
          /> */}
          <CustomInput
            label="Address"
            name="address1"
            placeholder={"Ex: Project Manager"}
            control={control}
            errors={errors}
          />
          <CustomInput
            label={"City"}
            name="city"
            placeholder={"Ex: United Kingdom"}
            control={control}
            errors={errors}
          />
          <CustomInput
            label={"State/Province"}
            name="state"
            placeholder={"Ex: United Kingdom"}
            control={control}
            errors={errors}
          />
          <CustomInput
            label={"Postal/ZIP Code"}
            name="zipCode"
            placeholder={"Ex: United Kingdom"}
            control={control}
            errors={errors}
          />
          <CustomInput
            label={"Country"}
            name="country"
            placeholder={"Ex: United Kingdom"}
            control={control}
            errors={errors}
          />
        </div>
        <button
          disabled={isLoading || !isDirty}
          onClick={handleSubmit(updateCompanyDetails)}
          className={`bg-primary items-center flex text-white px-8 py-2 text-sm font-semibold rounded-md w-max ml-auto mt-3 ${
            isDirty ? "opacity-100 hover:bg-black" : "opacity-50"
          }`}
        >
          {isLoading && <LoadingAnimation color={colors.White} />}
          Update
        </button>
      </div>
    </div>
  );
};

export default MyCompanyDetailsPage;
